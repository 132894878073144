import Image from "next/image";
import classNames from "classnames";
interface PercentCardProps {
  Title?: string;
  Body?: string;
  Percentage?: number;
  BigCard?: boolean;
}

interface PercentCardsProps {
  data: {
    HideSection: boolean;
    Title?: string;
    Body?: string;
    MobileText?: string;
    EndText?: string;
    PercentCardContent?: PercentCardProps[];
    CustomClassName: string;
    CustomCSS?: string;
  };
}

const PercentCards: React.FC<PercentCardsProps> = ({ data }) => {
  if (data?.HideSection) return null;

  return (
    <>
      {data?.CustomCSS && data?.CustomCSS !== "" && (
        <style>{data?.CustomCSS}</style>
      )}
      <div className={classNames("percent-cards", data?.CustomClassName || "")}>
        <div className="container">
          <div className="percent-cards__grid">
            <div className="percent-cards__content">
              <div className="percent-cards__content-up">
                {data?.Title && (
                  <h3 className="percent-cards__title">{data?.Title}</h3>
                )}
                {data?.Body && (
                  <p className="percent-cards__body">{data?.Body}</p>
                )}
              </div>
              {data?.EndText && (
                <p className="percent-cards__end-text">{data?.EndText}</p>
              )}
            </div>
            <div className="percent-cards__cards-grid">
              {data?.PercentCardContent?.map(
                (item: PercentCardProps, key: number) => (
                  <PercentCard data={item} key={key} />
                )
              )}
            </div>
            <div className="percent-cards__mobile">
              {data?.MobileText && (
                <div className="percent-cards__mobile-text">
                  {data?.MobileText}
                </div>
              )}{" "}
              <div className="percent-cards__cards-list">
                {data?.PercentCardContent?.map(
                  (item: PercentCardProps, key: number) => {
                    if (!item.Percentage) {
                      return <PercentCard data={item} key={key} />;
                    }
                  }
                )}
              </div>
              {data?.EndText && (
                <div className="percent-cards__end-text">{data?.EndText}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function PercentCard({ data }: { data: PercentCardProps }) {
  return (
    <div
      className={classNames("percent-card", {
        "percent-card--big": data.BigCard,
        "percent-card--percentage": data.Percentage,
      })}
    >
      {data.Percentage && (
        <>
          <div className="percent-card__percentage">{`${data.Percentage}%`}</div>
          <div className="percent-card__progress">
            <div
              className="percent-card__progress-line"
              style={{ width: data.Percentage + "%" }}
            />
            <div className="percent-card__progress-bar" />
          </div>
        </>
      )}
      <h5>
        <Image
          alt="tick icon"
          src="/Tick-green.svg"
          width={24}
          height={24}
          className="percent-card__tick"
        />
        {data?.Title}
      </h5>
      <p>{data?.Body}</p>
    </div>
  );
}

export default PercentCards;
