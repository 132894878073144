import Image from "next/image";
import { getStrapiMedia } from "utils";
import classNames from "classnames";

interface IconCardContentProps {
  Text: string;
  Icon?: any;
  Body?: string;
  Image?: any;
}

interface IconCardProps {
  item: IconCardContentProps;
  index?: number;
  dataLength?: number;
  type: "grid" | "carousel";
  selectedIndex?: number | null;
  iconSize?: number;
  align?: "left" | "center" | "right";
  extra?: boolean;
  progress?: boolean;
  onClick?: () => void;
}

function IconCard({
  item,
  index,
  dataLength,
  type,
  selectedIndex,
  iconSize,
  align,
  extra,
  progress,
  onClick,
}: IconCardProps) {
  return (
    <>
      <div
        className={classNames("icon-card", {
          embla__slide: type === "carousel",
          "embla__slide--active": selectedIndex === index,
          "icon-card--left": align === "left",
          "icon-card--right": align === "right",
        })}
        onClick={onClick}
      >
        <div className="icon-card__head">
          {item.Icon && item.Icon.data && (
            <div className="icon-card__icon">
              <Image
                src={getStrapiMedia(item.Icon)}
                alt="Icon card icon"
                width={iconSize || 24}
                height={iconSize || 24}
              />
            </div>
          )}
          <p className="icon-card__text">{item.Text}</p>
          {extra && (
            <div className="icon-card__plus">
              <Image
                src="/plus.svg"
                alt="Icon plus"
                width={iconSize || 24}
                height={iconSize || 24}
              />
            </div>
          )}
        </div>
        {item.Body && <p className="icon-card__body-text">{item.Body}</p>}
        {progress && <div className="icon-card__progress" />}
      </div>
      {extra && dataLength && dataLength - 1 !== index && (
        <div className="icon-card__plus">
          <Image
            src="/plus.svg"
            alt="Icon plus"
            width={iconSize || 24}
            height={iconSize || 24}
          />
        </div>
      )}
    </>
  );
}

export default IconCard;
export type { IconCardContentProps, IconCardProps };
