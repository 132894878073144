import { useEffect, useMemo, useRef, useState } from "react";
import Image from "next/image";
import classNames from "classnames";
import { useRouter } from "next/router";

interface PriceCardProps {
  Title?: string;
  DetailsList?: any;
  ButtonLabel?: string;
  ButtonLink?: string;
}

interface PricingProps {
  data: {
    Title: string;
    Subtitle?: string;
    PriceCard: PriceCardProps[];
    HideSection: boolean;
    SelectedCardIndex: number;
  };
}
const PriceCards: React.FC<PricingProps> = ({ data }) => {
  if (data?.HideSection) return null;

  const { locale } = useRouter();
  const [selectedCard, setSelectedCard] = useState(data.SelectedCardIndex - 1);

  const mergedDetailsList: any = useMemo(() => setMergedDetailsList(), []);

  function setMergedDetailsList() {
    let mergedDetailsListArray: any[] = [];
    if (data.PriceCard) {
      data.PriceCard.forEach((item: any, key: number) => {
        if (key === 0)
          mergedDetailsListArray = mergedDetailsListArray.concat(
            item.DetailsList
          );
        else
          item.DetailsList.forEach((element: any, index: number) => {
            mergedDetailsListArray[index][`detail${key}`] = element.detail;
          });
      });
    }
    return mergedDetailsListArray;
  }

  const ref = useRef<any>(null);
  useEffect(() => {
    let maxHeight = 0;
    if (ref.current) {
      ref.current.querySelectorAll("li").forEach((item: any) => {
        if (item.offsetHeight > maxHeight) {
          maxHeight = item.offsetHeight;
        }
      });

      ref.current.querySelectorAll("li").forEach((item: any) => {
        item.style.height = `${maxHeight}px`;
      });
    }
  });
  return (
    <div className="pricing-cards">
      <div className="container">
        <div className="pricing-cards__grid">
          <div className="pricing-cards__head">
            {data.Subtitle && <p>{data.Subtitle}</p>}
            <h3>{data.Title}</h3>
          </div>
          <div className="pricing-cards__content" ref={ref}>
            {data.PriceCard &&
              data.PriceCard.map((item: PriceCardProps, key: number) => (
                <div
                  className={classNames("price-card", {
                    "price-card--active": selectedCard === key,
                  })}
                  key={key}
                >
                  <div className="price-card__head">
                    <h5>{item.Title}</h5>
                  </div>
                  <div className="price-card__body">
                    <ul>
                      {item.DetailsList &&
                        item.DetailsList.map((item: any, key: number) => (
                          <li key={key}>
                            <Image
                              alt=""
                              src="/Tick-green.svg"
                              width={20}
                              height={20}
                            />
                            <div>
                              <h6>{item.title}</h6>
                              <p>{item.detail}</p>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="price-card__footer">
                    {item.ButtonLabel && item.ButtonLink && (
                      <a
                        className="btn btn--white"
                        href={`/${locale}${item.ButtonLink}`}
                      >
                        {item.ButtonLabel}
                      </a>
                    )}
                  </div>
                </div>
              ))}
          </div>
          <div className="pricing-cards__content--mobile">
            <div className="price-card--mobile">
              <div className="price-card__head">
                {data.PriceCard &&
                  data.PriceCard.map((item: PriceCardProps, key: number) => (
                    <button
                      type="button"
                      className={classNames({ active: selectedCard === key })}
                      onClick={() => setSelectedCard(key)}
                      key={key}
                    >
                      {item.Title}
                    </button>
                  ))}
                {selectedCard === 1 && (
                  <style jsx>{`
                    .price-card--mobile
                      .price-card__head
                      .price-card__active--btn {
                      left: 50%;
                    }
                  `}</style>
                )}
                <div className="price-card__active--btn" />
              </div>
              <div className="price-card__body">
                <style jsx>{`
                  .detail-item--${selectedCard} {
                    display: block !important;
                  }
                `}</style>
                <ul>
                  {mergedDetailsList &&
                    mergedDetailsList.map((item: any, key: number) => (
                      <li key={key}>
                        <Image
                          alt=""
                          src="/Tick-green.svg"
                          width={20}
                          height={20}
                        />
                        <div>
                          <h6>{item.title}</h6>
                          <p className="detail-item--0">{item.detail}</p>
                          {data.PriceCard &&
                            data.PriceCard.map((element: any, key: number) => {
                              if (key > 0 && item[`detail${key}`]) {
                                return (
                                  <p
                                    className={`detail-item--${key}`}
                                    key={key}
                                  >
                                    {item[`detail${key}`]}
                                  </p>
                                );
                              }
                            })}
                        </div>
                      </li>
                    ))}
                </ul>
              </div>

              <div className="price-card__footer">
                <style jsx>{`
                  .price-card__footer
                    .btn--pricing.btn--pricing-${selectedCard} {
                    display: block !important;
                  }
                `}</style>
                {data.PriceCard &&
                  data.PriceCard.map(
                    (item: PriceCardProps, key: number) =>
                      item.ButtonLabel &&
                      item.ButtonLink && (
                        <a
                          className={`btn btn--fill btn--pricing btn--pricing-${key}`}
                          href={item.ButtonLink}
                          key={key}
                        >
                          {item.ButtonLabel}
                        </a>
                      )
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceCards;
