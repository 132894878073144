import Image from "next/image";
import classNames from "classnames";
import { useRouter } from "next/router";

import { getStrapiMedia } from "utils";

import type { IconCardContentProps } from "./icon-card";
import { useEffect, useRef, useState } from "react";
import useWindowSizes from "hooks/use-window-sizes";

interface IconCardsExtraProps {
  data: {
    IconCardContent: IconCardContentProps[];
    HideSection: boolean;
    Title?: string;
    ButtonLabel?: string;
    ButtonLink?: string;
    ButtonIcon?: any;
    ContainerWidth: number;
    CustomCSS?: string;
    CustomClassName: string;
  };
}

const IconCardsExtra: React.FC<IconCardsExtraProps> = ({ data }) => {
  if (data?.HideSection) return null;
  const { locale } = useRouter();

  const [activeMobileCard, setActiveMobileCard] = useState<number | null>(null);

  const handleMobileClick = (index: number) => {
    if (activeMobileCard === index) {
      setActiveMobileCard(null);
    } else {
      setActiveMobileCard(index);
    }
  };

  return (
    <>
      {data?.CustomCSS && data?.CustomCSS !== "" && (
        <style>{data?.CustomCSS}</style>
      )}
      {data?.ContainerWidth && (
        <style jsx>{`
          .${data?.CustomClassName} .icon-cards__grid {
            max-width: ${data?.ContainerWidth}px;
          }
        `}</style>
      )}
      <div
        className={classNames(
          "icon-cards icon-cards--extra",
          data?.CustomClassName || ""
        )}
      >
        <div className="container">
          {data?.Title && <h3>{data.Title}</h3>}

          <div className="icon-cards__grid icon-cards__grid--desktop">
            <div className="icon-cards--grid-row">
              {data?.IconCardContent && data?.IconCardContent.length > 0 && (
                <>
                  {data.IconCardContent.map(
                    (item: IconCardContentProps, key: number) => {
                      if (key != 1 && key != 0) return null;
                      return (
                        <>
                          {key === 1 && (
                            <div className="icon-card__plus">
                              <Image
                                src="/plus.svg"
                                alt="Icon plus"
                                width={24}
                                height={24}
                              />
                            </div>
                          )}
                          <Card item={item} key={key} active={false} />
                        </>
                      );
                    }
                  )}
                </>
              )}
            </div>
            <div className="icon-cards--grid-row">
              {data?.IconCardContent && data?.IconCardContent.length > 0 && (
                <>
                  {data.IconCardContent.map(
                    (item: IconCardContentProps, key: number) => {
                      if (key != 2 && key != 3) return null;
                      return (
                        <>
                          <div className="icon-card__plus">
                            <Image
                              src="/plus.svg"
                              alt="Icon plus"
                              width={24}
                              height={24}
                            />
                          </div>
                          <Card item={item} key={key} active={false} />
                        </>
                      );
                    }
                  )}
                </>
              )}
            </div>
            <div className="icon-cards--grid-row">
              {data?.IconCardContent && data?.IconCardContent.length > 0 && (
                <>
                  {data.IconCardContent.map(
                    (item: IconCardContentProps, key: number) => {
                      if (key != 4 && key != 5) return null;
                      return (
                        <>
                          <div className="icon-card__plus">
                            <Image
                              src="/plus.svg"
                              alt="Icon plus"
                              width={24}
                              height={24}
                            />
                          </div>
                          <Card item={item} key={key} active={false} />
                        </>
                      );
                    }
                  )}
                </>
              )}
            </div>
          </div>
          <div className="icon-cards__grid icon-cards__grid--mobile">
            {data?.IconCardContent && data?.IconCardContent.length > 0 && (
              <>
                {data.IconCardContent.map(
                  (item: IconCardContentProps, key: number) => (
                    <Card
                      item={item}
                      key={key}
                      active={activeMobileCard === key ? true : false}
                      onClick={() => handleMobileClick(key)}
                    />
                  )
                )}
              </>
            )}
          </div>

          {data?.ButtonLabel && data?.ButtonLink && (
            <a className="btn btn--white" href={`/${locale}${data.ButtonLink}`}>
              <span>{data.ButtonLabel}</span>
              {data.ButtonIcon && (
                <Image
                  alt=""
                  src={getStrapiMedia(data.ButtonIcon)}
                  width={24}
                  height={24}
                />
              )}
            </a>
          )}
        </div>
        <div className="icon-cards--extra__circle-wrap">
          <div className="icon-cards--extra__circle" />
        </div>
      </div>
    </>
  );
};

interface IconCardProps {
  item: IconCardContentProps;
  iconSize?: number;
  active?: boolean;
  onClick?: () => void;
}

function Card({ item, iconSize, active, onClick }: IconCardProps) {
  const [isHover, setIsHover] = useState(false);
  const [textWidth, setTextWidth] = useState(0);
  const refCardHead = useRef<any>();

  useEffect(() => {
    if (!refCardHead.current) return;
    setTextWidth(refCardHead.current.offsetWidth);
  });
  const { width } = useWindowSizes();

  return (
    <>
      <div
        className={classNames("icon-card--extra", {
          "icon-card--active": active || isHover,
        })}
        onClick={onClick}
        {...(width > 768 && { onMouseEnter: () => setIsHover(!isHover) })}
        {...(width > 768 && { onMouseLeave: () => setIsHover(!isHover) })}
      >
        <div className="icon-card__head" ref={refCardHead}>
          {item?.Icon && item.Icon.data && (
            <div className="icon-card__icon">
              <Image
                src={getStrapiMedia(item.Icon)}
                alt="Icon card icon"
                width={iconSize || 24}
                height={iconSize || 24}
              />
            </div>
          )}
          <p className="icon-card__text">{item?.Text}</p>
          <div className="icon-card__plus">
            <Image
              src="/plus.svg"
              alt="Icon plus"
              width={iconSize || 24}
              height={iconSize || 24}
            />
          </div>
        </div>
        {item.Body && (
          <p className="icon-card__body-text" style={{ maxWidth: textWidth }}>
            {item.Body}
          </p>
        )}
      </div>
    </>
  );
}

export default IconCardsExtra;
