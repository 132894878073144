import { useEffect, useState } from "react";
import Image from "next/image";
import classNames from "classnames";

import { getStrapiMedia } from "utils";

import type { IconCardContentProps } from "./icon-card";
import IconCard from "./icon-card";

interface IconCardsWithMediaProps {
  data: {
    IconCardContent: IconCardContentProps[];
    HideSection: boolean;
    AutoplayDurationMs: number;
    CustomCSS?: string;
    CustomClassName: string;
    SingleImage?: any;
  };
}

const IconCardsWithMedia: React.FC<IconCardsWithMediaProps> = ({ data }) => {
  if (data?.HideSection) return null;

  const autoplayDuration = data?.AutoplayDurationMs;
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (selectedIndex === data?.IconCardContent.length - 1) {
        setSelectedIndex(0);
      } else {
        setSelectedIndex(selectedIndex + 1);
      }
    }, autoplayDuration);
  }, [selectedIndex]);

  return (
    <>
      {data?.CustomCSS && data?.CustomCSS !== "" && (
        <style>{data?.CustomCSS}</style>
      )}
      {data?.CustomClassName && (
        <style jsx>{`
          .${data?.CustomClassName} img.icon-card__image--${selectedIndex} {
            opacity: 1 !important;
          }
        `}</style>
      )}

      <div
        className={classNames(
          "icon-cards icon-cards--with-media",
          data?.CustomClassName || ""
        )}
      >
        <div className="container">
          <div className="icon-cards--with-media__grid">
            <div className="icon-cards__grid icon-cards__grid--card">
              {autoplayDuration > 0 && (
                <style jsx>{`
                  .${data?.CustomClassName} .icon-card__progress {
                    transition-duration: ${autoplayDuration}ms;
                  }
                `}</style>
              )}
              {data?.IconCardContent && (
                <>
                  {data.IconCardContent.map(
                    (item: IconCardContentProps, key: number) => (
                      <IconCard
                        item={item}
                        index={key}
                        type="grid"
                        iconSize={24}
                        align="left"
                        progress={true}
                        selectedIndex={selectedIndex}
                        key={key}
                        onClick={() => setSelectedIndex(key)}
                      />
                    )
                  )}
                </>
              )}
            </div>
            <div className="icon-cards__media">
              {data?.SingleImage && data?.SingleImage.data && (
                <Image
                  alt={`Image`}
                  className="icon-card__image icon-card__image--single"
                  src={getStrapiMedia(data.SingleImage)}
                  width={1200}
                  height={1200}
                />
              )}
              {!data.SingleImage &&
                data?.IconCardContent &&
                data?.IconCardContent.length > 0 &&
                data?.IconCardContent.map(
                  (item: IconCardContentProps, key: number) => (
                    <Image
                      alt={`Image ${key}`}
                      className={`icon-card__image icon-card__image--${key}`}
                      src={getStrapiMedia(item.Image)}
                      width={1200}
                      height={1200}
                      key={key}
                    />
                  )
                )}
            </div>
          </div>
        </div>
        <div className="container media--container">
          <div className="icon-cards--with-media__grid">
            <div className="icon-cards__grid icon-cards__grid--card"></div>
            <div className="icon-cards__media">
              {data?.SingleImage && data?.SingleImage.data && (
                <Image
                  alt={`Image`}
                  className="icon-card__image icon-card__image--single"
                  src={getStrapiMedia(data.SingleImage)}
                  width={1200}
                  height={1200}
                />
              )}
              {!data.SingleImage &&
                data?.IconCardContent &&
                data?.IconCardContent.length > 0 &&
                data?.IconCardContent.map(
                  (item: IconCardContentProps, key: number) => (
                    <Image
                      alt={`Image ${key}`}
                      className={`icon-card__image icon-card__image--${key}`}
                      src={getStrapiMedia(item.Image)}
                      width={1200}
                      height={1200}
                      key={key}
                    />
                  )
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IconCardsWithMedia;
