import { useState, useEffect } from "react";

const useWindowSizes = () => {
  const [windowSizes, setWindowSizes] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window;
    setWindowSizes({
      width,
      height,
    });
    function handleResize() {
      const { innerWidth: width, innerHeight: height } = window;
      setWindowSizes({
        width,
        height,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSizes;
};

export default useWindowSizes;
