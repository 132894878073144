import { useEffect, useRef } from "react";
import Image from "next/image";
import classNames from "classnames";
import path from "path";
import { useRouter } from "next/router";

// gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import SplitType from "split-type";

import { getStrapiMedia } from "utils";
interface ScrollTextProps {
  data: {
    HideSection: boolean;
    StartText: string;
    RestOfText: string;
    CustomCSS?: string;
    CustomClassName: string;
    ButtonLabel?: string;
    ButtonLink?: string;
    ButtonIcon?: any;
    ButtonLabelDownload?: string;
    ButtonIconDownload?: any;
    DownloadContent?: any;
    ContainerWidth: number;
  };
}
gsap.registerPlugin(ScrollTrigger);

const ScrollText: React.FC<ScrollTextProps> = ({ data }) => {
  if (data?.HideSection) return null;
  const textRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    SplitType.create(textRef.current as any, {
      types: "words",
      wordClass: "color-toggle",
    });
    gsap.utils
      .toArray<HTMLParagraphElement>(".color-toggle")
      .forEach((colorSection) => {
        ScrollTrigger.create({
          trigger: colorSection,
          start: "top center",
          toggleClass: "active",
        });
      });
    return () => {};
  }, []);
  const { locale } = useRouter();

  return (
    <>
      {data?.CustomCSS && data?.CustomCSS !== "" && (
        <style>{data?.CustomCSS}</style>
      )}
      <div className={classNames("scroll-text", data?.CustomClassName || "")}>
        <div className="container">
          <div className="scroll-text__content">
            <p>
              <span className="scroll-text__content--start">
                {data?.StartText}
              </span>{" "}
              <span className="scroll-text__content--rest" ref={textRef}>
                {data?.RestOfText}
              </span>
            </p>
          </div>
          <div className="scroll-text__cta">
            {data?.ButtonLabel && data?.ButtonLink && (
              <a
                className="btn btn--fill"
                href={`/${locale}${data.ButtonLink}`}
              >
                <span>{data.ButtonLabel}</span>
                {data.ButtonIcon && data.ButtonIcon.data && (
                  <Image
                    alt="Button Icon"
                    src={getStrapiMedia(data.ButtonIcon)}
                    width={24}
                    height={24}
                  />
                )}
              </a>
            )}
            {data?.ButtonLabelDownload &&
              data?.DownloadContent &&
              data?.DownloadContent?.data && (
                <a
                  className="btn btn--white"
                  href={path.join(
                    process.env.NEXT_PUBLIC_STRAPI_API_URL || "",
                    data?.DownloadContent?.data.attributes.url
                  )}
                  target="_blank"
                  download
                >
                  <span>{data.ButtonLabelDownload}</span>
                  {data.ButtonIconDownload && data.ButtonIconDownload.data && (
                    <Image
                      alt="Download Icon"
                      src={getStrapiMedia(data.ButtonIconDownload)}
                      width={24}
                      height={24}
                    />
                  )}
                </a>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ScrollText;
