import { useEffect, useState, useCallback } from "react";
import Image from "next/image";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

import { getStrapiMedia } from "utils";
import classNames from "classnames";

import type { IconCardContentProps } from "./icon-card";
import IconCard from "./icon-card";

interface IconCardsProps {
  data: {
    IconCardContent: IconCardContentProps[];
    IconSize: number;
    AutoplayDurationMs: number;
    HideSection: boolean;
    MobileView: "carousel" | "slider" | "grid";
    DesktopView: "border grid" | "card grid";
    ContentPosition: "left" | "center" | "right";
    Title?: string;
    TitleColored?: string;
    Subtitle?: string;
    OfficialPartnerBadge?: boolean;
    CustomCSS?: string;
    CustomClassName: string;
  };
}

const IconCards: React.FC<IconCardsProps> = ({ data }) => {
  if (data?.HideSection) return null;

  const autoplayDuration = data?.AutoplayDurationMs;

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [scrollSnaps, setScrollSnaps] = useState<Array<number>>([]);

  const emblaPlugins = [
    Autoplay({
      delay: autoplayDuration,
    }),
  ];
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: data?.MobileView === "carousel" ? true : false,
      align: "start",
    },
    autoplayDuration > 0 || data?.MobileView === "slider" ? emblaPlugins : []
  );

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on("select", onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  return (
    <>
      {data?.CustomCSS && data?.CustomCSS !== "" && (
        <style>{data?.CustomCSS}</style>
      )}
      <div className={classNames("icon-cards", data?.CustomClassName || "")}>
        <div className="container">
          {data?.IconCardContent && data?.IconCardContent.length > 0 && (
            <style jsx>{`
              @media (min-width: 768px) {
                .${data?.CustomClassName} .icon-cards__grid {
                  grid-template-columns: repeat(
                    ${data?.IconCardContent.length},
                    minmax(0, 1fr)
                  );
                }
              }
            `}</style>
          )}
          {(data?.MobileView === "carousel" ||
            data?.MobileView === "slider") && (
            <style jsx>{`
              @media (max-width: 767px) {
                .${data?.CustomClassName} .icon-cards__grid {
                  display: none;
                }
              }
            `}</style>
          )}
          {data?.MobileView === "slider" && (
            <style jsx>{`
              @media (max-width: 767px) {
                .${data?.CustomClassName} .container {
                  padding: 0;
                }
              }
            `}</style>
          )}
          {(data?.Title ||
            data?.TitleColored ||
            data?.Subtitle ||
            data?.OfficialPartnerBadge) && (
            <div className="icon-cards__head">
              {data?.OfficialPartnerBadge && (
                <div className="official-partner__badge">
                  <Image
                    alt="Shopify logo"
                    src="/shopify.svg"
                    width={15}
                    height={16}
                  />
                  <span>Official Partner</span>
                </div>
              )}
              {(data?.Title || data?.TitleColored) && (
                <h4 className="icon-cards__title">
                  {data?.Title && <span>{data?.Title}</span>}
                  {data?.Title && data?.TitleColored && <span> </span>}
                  {data?.TitleColored && (
                    <span className="icon-cards__title--colored">
                      {data?.TitleColored}
                    </span>
                  )}
                </h4>
              )}
              {data?.Subtitle && (
                <p className="icon-card__subtitle">{data?.Subtitle}</p>
              )}
            </div>
          )}
          <div
            className={classNames("icon-cards__grid", {
              "icon-cards__grid--card": data?.DesktopView === "card grid",
            })}
          >
            {data?.IconCardContent && data?.IconCardContent.length > 0 && (
              <>
                {data.IconCardContent.map(
                  (item: IconCardContentProps, key: number) => (
                    <IconCard
                      item={item}
                      index={key}
                      type="grid"
                      iconSize={data?.IconSize}
                      align={data?.ContentPosition}
                      key={key}
                    />
                  )
                )}
              </>
            )}
          </div>
          {(data?.MobileView === "carousel" ||
            data?.MobileView === "slider") && (
            <div
              className={classNames("embla", {
                "embla--carousel": data?.MobileView === "carousel",
                "embla--slider": data?.MobileView === "slider",
              })}
            >
              {autoplayDuration > 0 && (
                <style jsx>{`
                  .${data?.CustomClassName} .icon-card__progress {
                    transition-duration: ${autoplayDuration}ms;
                  }
                `}</style>
              )}
              <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                  {data?.IconCardContent &&
                    data?.IconCardContent.length > 0 && (
                      <>
                        {data.IconCardContent.map(
                          (item: IconCardContentProps, key: number) => (
                            <IconCard
                              item={item}
                              index={key}
                              type="carousel"
                              selectedIndex={selectedIndex}
                              iconSize={data?.IconSize}
                              align={data?.ContentPosition}
                              progress={
                                data?.MobileView === "carousel" ? true : false
                              }
                              key={key}
                            />
                          )
                        )}
                      </>
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default IconCards;
