import { useRouter } from "next/router";
import { usePathname } from "next/navigation";
import { useForm, SubmitHandler } from "react-hook-form";
import qs from "qs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { fetchAPI } from "utils";
import type { FormBuilderProp, FormFieldProp } from "types/forms";
import FormFields from "./form-fields";
import Markdown from "components/markdown/markdown";

interface FormBuilderProps extends FormBuilderProp {
  slug: string;
}

const FormBuilder: React.FC<FormBuilderProps> = ({ data, slug }) => {
  const router = useRouter();
  const pathname = usePathname();

  const names = data?.FormField?.reduce((acc: any, val: any) => {
    acc[val.name] = "";
    return acc;
  }, {});

  let defaultValues = {
    ...names,
    source: slug,
  };

  data?.FormField.forEach((item: FormFieldProp) => {
    if (item.Type === "checkbox") {
      defaultValues[item.name] = false;
    } else if (item.Type === "select") {
      defaultValues[item.name] = null;
    }
  });

  const {
    reset,
    resetField,
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const onSubmit: SubmitHandler<any> = (submitData) => {
    const newData = new FormData();
    const dataObj: any = {
      publishedAt: null,
      source: submitData.source,
      hooks: data?.ZapierHooks || [],
      sendEmail: data.SendEmail,
    };

    data?.FormField.map((item: FormFieldProp) => {
      if (item.Type !== "file") {
        dataObj[item.name] = submitData[item.name];
      } else if (submitData[item.name]) {
        newData.append(
          `files.${item.name}`,
          submitData[item.name],
          submitData[item.name].name
        );
      }
    });

    newData.append("data", JSON.stringify(dataObj));

    return fetchAPI(
      "/dynamic-form-entries/submit",
      {},
      {
        method: "post",
        headers: {},
        body: newData,
      }
    )
      .then((res) => {
        if (res.error) {
          toast.error("There was an error submitting the form!");
          return;
        }
        toast("Form submitted!");
        // gtm gtag custom event trigger reedirect
        // set gtm event data with form data for url params
        // redirect to submission page with url params
        if (data?.GTM_Event) {
          let queryObj = {
            path: pathname.replace("/", ""),
            action: data?.GTM_Event.action_name || "custom_event",
            category: data?.GTM_Event.event_category,
            label: data?.GTM_Event.event_label,
          };
          if (data?.GTM_Event.event_value) {
            data.GTM_Event?.event_value?.split(",").forEach((item: string) => {
              item = item.trim();
              queryObj = {
                ...queryObj,
                [item]: submitData[item],
              };
            });
          }
          setTimeout(() => {
            router.push(`/thank-you?${qs.stringify(queryObj)}`, "", {});
          }, 3000);
        }
        reset();
      })
      .catch(() => {
        toast.error("There was an error submitting the form!");
      });
  };

  return (
    <>
      {data?.CustomCSS && data?.CustomCSS !== "" && (
        <style>{data?.CustomCSS}</style>
      )}
      <div className="form--builder">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            {data?.Content && (
              <div className="form--builder__subtitle hidden-lg">
                <Markdown content={data?.Content} />
              </div>
            )}
            <h5>{data?.Title}</h5>
            {data?.Content && (
              <div className="form--builder__subtitle hidden-xs">
                <Markdown content={data?.Content} />
              </div>
            )}
            <input type="hidden" {...register("source")} />
            <div className="form--builder__grid">
              {data?.FormField &&
                data?.FormField.map((item: FormFieldProp, key: number) => (
                  <FormFields
                    item={item}
                    key={key}
                    className="form--builder__element form--builder__grid-column-full"
                    control={control}
                    errors={errors}
                    resetField={resetField}
                  />
                ))}
            </div>
            <button
              className="btn btn--fill"
              type="submit"
              disabled={isSubmitting}
            >
              {data?.SubmitText}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormBuilder;
