import classNames from "classnames";
import { getStrapiMediaArr } from "utils";

interface TrustedByGrowthProps {
  data: {
    Display: boolean;
    Title: string;
    Logos: {
      data: any[];
    };
    Container?: boolean;
  };
}

const TrustedByGrowth: React.FC<TrustedByGrowthProps> = ({ data }) => {
  if (!data?.Display) return null;

  const logos = [...(data?.Logos?.data || []), ...(data?.Logos?.data || [])];

  return (
    <div id="growthTrustedBy" className="trusted-by--growth">
      <div
        className={classNames("container", {
          "container--full": !data?.Container,
        })}
      >
        <h5>{data?.Title}</h5>
        <div className="marquee">
          <div className="marquee__group">
            {logos.map((item: any, key: number) => (
              <img
                src={getStrapiMediaArr(item)}
                alt={item.caption || "logo"}
                key={key}
              />
            ))}
          </div>
          <div aria-hidden="true" className="marquee__group">
            {logos.map((item: any, key: number) => (
              <img
                src={getStrapiMediaArr(item)}
                alt={item.caption || "logo"}
                key={key}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedByGrowth;
